#historique .entete {
  height: 60vh;
  text-align: center;
  display: grid;
  align-content: center;
  background-image: url("https://static.mediarestauration.fr/img/fond-historique.jpg");
  background-color: #f6f6f6;
  background-repeat: no-repeat;
  background-position: 74% 100%;
  background-attachment: scroll;
  background-size: cover;
}
.entete_fond {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #1a1939 no-repeat center center;
  background-image: url("https://static.mediarestauration.fr/img/fond-historique.jpg");
  background-attachment: fixed;
  background-size: cover;
  animation: shrink 5s infinite alternate steps(60);
}

@keyframes shrink {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
#timeline-wrap {
  margin: 1%;
  top: 100;
  color: #6f1726;
  position: relative;
}

#timeline {
  height: 1px;
  width: 100%;
  background-color: #aabbc4;
  position: relative;
}

.marker {
  z-index: 1000;
  color: #fff;
  width: 65px;
  height: 65px;
  line-height: 60px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  margin-left: -25px;
  background-color: #6f1726;
  border-radius: 50%;
}

.marker:hover {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.timeline-icon.one {
  background-color: #9c8ba6 !important;
}

.mfirst {
  top: -30px;
}

.mlast {
  top: -30px;
  left: 100%;
}

.timeline-panel {
  margin-top: 20%;
  width: 500px;
  height: 200px;
  background-color: #cbd0df;
  border-radius: 2px;
  position: relative;
  text-align: left;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  float: left;
}

.timeline-panel:after {
  content: "";
  position: absolute;
  margin-top: -12%;
  left: 10%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom: 15px solid #cbd0df;
}
@media only screen and (min-width: 744px) {
  #historique .entete {
    background-position: 88% 100%;
    background-size: cover;
  }
}
.bouton_modal {
  float: right;
}
h4 {
  color: #6f1726;
  font-weight: 300;
}
ul {
  border-left: 2px solid #000;
}
li {
  font-size: 20px;
}
.ReactModal__Overlay {
  background-color: rgba(38, 31, 31, 0.75) !important;
  opacity: 0;
  filter: blur(5px);
}

.ReactModal__Overlay--after-open {
  filter: blur(0px);
  opacity: 1;
  transition: filter 350ms ease-out;
}
#compte span {
  font-size: 2.075rem;
  text-align: center;
  color: #6f1726;
}
