#nosReferences .entete {
  height: 60vh;
  text-align: center;
  display: grid;
  align-content: center;
  background-image: url(https://static.mediarestauration.fr/img/fond-reference.png),
    linear-gradient(0deg, rgba(251, 251, 251, 1) 0%, rgba(236, 232, 232, 1) 70%);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-attachment: scroll;
  background-size: cover;
}
@media only screen and (orientation: portrait) and (max-width: 805px) {
  .entete {
    background-size: contain;
    background-position: 88% 100%;
  }
}
@import "~react-image-gallery/styles/css/image-gallery.css";
.bp h6 {
  font-size: 23px;
}
.routard {
  max-height: 85px;
  width: auto;
}
.icone_lien_externe {
  display: inline-block;
  width: 23px;
  height: 18px;
  background-size: cover;
  background-image: url(../utilitaires/lien_externe.svg);
}
.bouton:hover .icone_lien_externe {
  filter: invert(100%) grayscale(100%);
  
}
