#contactezNous .entete {
  height: 60vh;
  text-align: center;
  display: grid;
  align-content: center;
  background-image: url("https://static.mediarestauration.fr/img/fond-entete-contactez-nous.jpg");
  background-repeat: no-repeat;
  background-position: 50vw;
  background-attachment: scroll;
  background-size: cover;
}
form {
  display: flex;
  flex-direction: column;
}

form input {
  padding: 16px;
  border: none;
  margin-bottom: 16px;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  border: 2px solid #b92640;
}

form textarea {
	padding: 16px;
	border: 2px solid #b92640;
	margin-bottom: 16px;
	outline: none;
	font-size: 14px;
	box-sizing: border-box;
	height: 150px;
	margin-top: 16px;
}

/* Error fields */

form .error {
  border: 2px solid #e53e3e;
}

form .errorText {
  color: #e53e3e;
  margin-bottom: 16px;
}
#envoye{
  display: none;
}
select {
	margin-bottom: 16px;
	outline: none;
	font-size: 14px;
	box-sizing: border-box;
	border: 2px solid #b92640;
	height: 50px;
  padding-left: 16px;
  width: 100%;
}
.disabled,.bouton.disabled:hover{
  pointer-events: none;
  -webkit-animation: zigzag 1s linear infinite;
          animation: zigzag 1s linear infinite;
  background: linear-gradient(135deg, rgba(185, 38, 64, 0.15) 0.25em, transparent 0.25em) -0.5em 0, linear-gradient(125deg, rgba(185, 38, 64, 0.15) 0.25em, transparent 0.25em) -0.5em 0, linear-gradient(31deg, rgba(238, 161, 99, 0.15) 0.25em, transparent 0.25em) 0 0, linear-gradient(45deg, rgba(238, 161, 99, 0.15) 0.25em, transparent 0.25em) 0 0;
  background-size: 0.75em 0.75em;
  color: #b92640;
  border: 1px solid #b92640;
}
@-webkit-keyframes zigzag {
  100% {
    background-position: 1em 0, 1em 0, -0.75em 0, -0.75em 0;
  }
}
@keyframes zigzag {
  100% {
    background-position: 1em 0, 1em 0, -0.75em 0, -0.75em 0;
  }
}