#nosServices .entete {
  height               : 60vh;
  text-align           : center;
  display              : grid;
  align-content        : center;
  background-image     : url("https://static.mediarestauration.fr/img/fond-entete-nos-services.jpg");
  background-repeat    : no-repeat;
  background-position  : 25%;
  background-attachment: scroll;
  background-size      : cover;
  background-position  : center;
}

#parallax1 {
  height                 : 35vh;
  background-attachment  : fixed;
  background-position    : center;
  background-repeat      : no-repeat;
  -webkit-background-size: cover;
  -moz-background-size   : cover;
  background-size        : cover;
  -o-background-size     : cover;
  width                  : 100%;
}

#parallax1 p {
  color: #000;
}

.ombre {
  box-shadow: rgba(0, 0, 0, 0.2) 0 20px 30px;
}

.bordure_droite {
  border-right: 1px solid #000;
}

.bordure_gauche {
  border-left: 1px solid #000;
}

.bordure_bas {
  border-bottom: 1px solid #000;
}

.bordure_haut {
  border-top: 1px solid #000;
}

.bordure_droite-row {
  border-right: 1px solid #dee2e6;
}

.bordure_bas-row {
  border-bottom: 1px solid #dee2e6;
}

.bordure_haut-row {
  border-top: 1px solid #dee2e6;
}

.bordure_gauche-row {
  border-left: 1px solid #dee2e6;
}

.curseur {
  cursor         : pointer;
  text-decoration: none;
  color          : #212529;
}

a.curseur:focus {
  outline: unset;
}

a.curseur:hover {
  color: #212529;
}

h3.popover-header {
  background-color: #16161d;
  text-align      : center;
  font-family     : "PierSans-Regular";
}

.popover-body {
  text-align : center;
  font-family: "PierSans-Regular";
}

.popover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 20px 30px;
}
.progress-icon {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 120px;
}