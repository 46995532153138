#nosProjets .entete {
  height: 60vh;
  text-align: center;
  display: grid;
  align-content: center;
  background-image: url("https://static.mediarestauration.fr/img/fond-projets-min.png"),
    linear-gradient(0deg, rgba(251, 251, 251, 1) 0%, rgba(236, 232, 232, 1) 70%);
  background-repeat: no-repeat;
  background-position: 100% 77%;
  background-attachment: scroll;
  background-size: auto;
}
