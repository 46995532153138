html,
body {
  font-family: "PierSans-Regular";
}
@supports (-webkit-touch-callout: none) {
  * {
    background-attachment: scroll;
  }
}
.fix__pour__safari {
  position: relative;
}
.fix__pour__safari_navmobile {
  position: absolute;
  width: 100vw;
}

@font-face {
  font-family: "PierSans-Regular";
  font-display: swap;
  src: url("https://static.mediarestauration.fr/polices/PierSans-Regular.woff2");
}
@font-face {
  font-family: "ITCAvantGardeGothicLTBookRegular";
  font-display: swap;
  src: url("https://static.mediarestauration.fr/polices/ITCAvantGardeGothicLTBookRegular.woff2");
}

h1 {
  font-size: 35px;
  font-family: "ITCAvantGardeGothicLTBookRegular";
}

h2 {
  font-size: 20px;
}

h5 {
  font-size: 50px;
  color: #b92640;
}

h4 {
  font-size: 35px;
  font-weight: 300;
}

h6 {
  font-size: 35px;
  color: #6f1726;
}

p {
  font-size: 20px;
}
.bordure_mobile {
  border: 1px solid #a92640;
  border-radius: 0;
}
.topnav {
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 62px;
  transition: 0.5s;
  color: #16161d;
  z-index: 10000;
  width: 100vw;
}

.topnav a {
  float: left;
  color: #b92640;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 350ms ease-in-out;
  background-position: left;
  background: none;
}

.topnav a:hover {
  color: #000;
  background-color: #b92640;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1134.286' height='388.571' viewBox='0 0 300.113 102.81'%3e%3cpath fill='%23903b57' d='M0 0h301.808v74.083H0z'/%3e%3cpath d='M0 57.414h302.786v47.266H0z' fill='%23b92741'/%3e%3c/svg%3e");
  background-position: right;
  transition: all 350ms ease-in-out;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #16161d;
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
  display: grid;
  align-content: center;
  z-index: 10001;
}

.sidenav a {
  padding: 5px 8px 8px 0;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: flex;
  transition: 0.3s;
  justify-content: center;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.entete .entete__bordure {
  border-top: 1px solid #b92640;
  border-bottom: 1px solid #b92640;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.fond_texte {
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.entete__bordure {
  border-top: 1px solid #b92640;
  border-bottom: 1px solid #b92640;
}
@media only screen and (min-width: 0px) and (max-width: 787px) {
  .sidenav {
    padding-top: 10px;
  }

  .sidenav a {
    font-size: 18px;
    color: #b92640;
  }

  .sidenav .active {
    color: #f1f1f1;
  }

  .topnav {
    display: none;
  }

  .entete {
    padding: 50px 10px 0;
  }

  .custom-shape-divider-bottom-1607979572 svg {
    display: none !important;
  }
}

.nav-mobile {
  height: 72px;
  transition: 0.8s;
}

.topnav a.active {
  color: #f1f1f1;
  background-color: #b92640;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1134.286' height='388.571' viewBox='0 0 300.113 102.81'%3e%3cpath fill='%23410d16' d='M0 0h301.808v74.083H0z'/%3e%3cpath d='M0 57.414h302.786v47.266H0z' fill='%23b92741'/%3e%3c/svg%3e");
  background-position: right;
  transition: all 350ms ease-in-out;
}

#barre-de-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}
@media screen and (min-width: 788px) {
  #barre-de-navigation {
    display: none;
  }

  #laBarredeNav {
    display: none;
  }
}
@media only screen and (min-width: 1477px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 25px;
  }

  h5 {
    font-size: 64px;
  }
}

.logo-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__bout-a-droite {
  position: absolute;
  right: 25px;
  color: #b92640;
}

#page {
  transition: 1.2s;
}

.entete h1,
h2,
h3 {
  color: #b92640;
}

h3 {
  font-size: 25px;
}

.bouton {
  color: #b92640;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 350ms ease-in-out;
  background-position: left;
  justify-content: center;
  border: 1px solid #b92640;
}

.bouton:hover {
  color: #000;
  background-color: #b92640;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='1134.286' height='388.571' viewBox='0 0 300.113 102.81'%3e%3cpath fill='%23410d16' d='M0 0h301.808v74.083H0z'/%3e%3cpath d='M0 57.414h302.786v47.266H0z' fill='%23b92741'/%3e%3c/svg%3e");
  background-position: right;
  transition: all 350ms ease-in-out;
  border: 1px solid transparent;
  color: white;
}

.fondu-entrant {
  background: #16161d;
  color: #b92640;
}

.topnav_apres {
  background: #16161d;
  color: #fff;
}

.topnav_apres a {
  color: #b92640;
}

.topnav .navbar__pas-de-hover:hover {
  background-color: unset;
  background-image: unset;
}

.topnav .navbar__pas-de-hover {
  background-color: unset !important;
  background-image: unset !important;
}

#laBarredeNav p {
  color: #b92640;
  line-height: 0.5;
}

.navbar__fixe {
  position: relative;
  left: 0;
}

.focus-text-entrant {
  -webkit-animation: focus-text-entrant 1s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: focus-text-entrant 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes focus-text-entrant {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-text-entrant {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

img {
  border-radius: 5px 5px 0 0;
}

.fond-blanc {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}

.fond-gris {
  background: #fbfbfb;
}

.espace {
  padding-top: 100px;
}

.espace_moins-grand {
  padding-top: 65px;
}

.couleur {
  color: #6f1726;
}
@media only screen and (min-width: 330px) and (max-width: 507px) {
  h5 {
    font-size: 40px;
  }

  h6 {
    font-size: 30px;
    color: #6f1726;
  }
}

.ombre-portee-centre:hover {
  -webkit-animation: ombre-portee-centre 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ombre-portee-centre 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  border: 1px solid transparent;
  border-radius: 3%;
  padding: 5px;
  margin: 0 5px;
}

.ombre-portee-centre {
  -webkit-animation: ombre-portee-centre-out 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
  animation: ombre-portee-centre-out 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    reverse both;
  border: 1px solid transparent;
  border-radius: 3%;
  padding: 5px;
  margin: 0 5px;
}
@keyframes ombre-portee-centre {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }

  100% {
    -webkit-transform: translateZ(50px) scale(1.02);
    transform: translateZ(50px) scale(1.02);
  }
}
@-webkit-keyframes ombre-portee-centre {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }

  100% {
    -webkit-transform: translateZ(50px) scale(1.02);
    transform: translateZ(50px) scale(1.02);
  }
}
@keyframes ombre-portee-centre-out {
  0% {
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }

  100% {
    -webkit-transform: translateZ(50px) scale(1.02);
    transform: translateZ(50px) scale(1.02);
  }
}
@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}

.bas-de-page {
  background-color: #16161d;
  color: #b9263a;
  position: absolute;
}

.itc {
  font-family: "ITCAvantGardeGothicLTBookRegular";
}
@media only screen and (orientation: landscape) and (max-width: 1200px) {
  #contactez-nous,
  #nos-projets,
  #nos-references,
  #nos-services,
  .entete {
    height: 100vh !important;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }
}

#lightboxBackdrop:first-child {
  margin-top: 5vh;
}

.a {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: underline;
}

.a:hover {
  color: #024dbc;
}

.close {
  position: absolute;
  right: 8px;
  top: 0;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.image-ronde {
  border-radius: 50%;
  cursor: pointer;
  width: 12rem;
  image-rendering: -webkit-optimize-contrast;
}
.clic {
  cursor: pointer;
}
.petit {
  font-size: 16px;
}
.ultra_petit{
  font-size: 14px;
}
.pp-modal {
  box-shadow: rgb(185, 38, 64) 13px -13px;
}
@supports (-webkit-overflow-scrolling: touch) {
  * {
    background-attachment: scroll !important;
  }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  * {
    background-attachment: scroll !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  * {
    background-attachment: scroll !important;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) {
  * {
    background-attachment: scroll !important;
  }
}
