@media only screen and (min-width: 0px) and (max-width: 787px) {
  #fond-reference {
    background-size: contain !important;
    padding-top: 50px !important;
  }

  #fond-historique {
    background-position: 68% 0 !important;
    background-size: contain !important;
  }

  #fond-projets {
    background-color: #fff;
    background-size: auto !important;
    background-position: 14% 46% !important;
    background-repeat: no-repeat;
  }
}
@media only screen and (min-width: 1477px) {
  #fond-projets {
    background-color: #fff;
    background-size: cover !important;
    background-position: 0 70% !important;
    background-repeat: no-repeat;
  }
}

.entete {
  height: 100vh;
  text-align: center;
  display: grid;
  align-content: center;
  /*background           : url("https://static.mediarestauration.fr/img/fond-entete.jpg");*/
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
}

#fond-reference {
  background-color: #fff;
  background-size: cover;
  background-position: 85% 100%;
  background-repeat: no-repeat;
  padding-top: 100px;
}

#fond-historique {
  background-color: #fff;
  background-size: cover;
  background-position: 60% 80%;
  background-repeat: no-repeat;
  padding-top: 100px;
}

#fond-projets {
  background-color: #fff;
  background-size: contain;
  background-position: right 109px;
  background-repeat: no-repeat;
}

#timeline-wrap {
  margin: 1%;
  top: 100;
  color: #6f1726;
  position: relative;
}

#timeline {
  height: 1px;
  width: 100%;
  background-color: #aabbc4;
  position: relative;
}

.marker {
  z-index: 1000;
  color: #fff;
  width: 65px;
  height: 65px;
  line-height: 60px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  margin-left: -25px;
  background-color: #6f1726;
  border-radius: 50%;
}

.marker:hover {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.timeline-icon.one {
  background-color: #9c8ba6 !important;
}

.mfirst {
  top: -30px;
}

.mlast {
  top: -30px;
  left: 100%;
}

.timeline-panel {
  margin-top: 20%;
  width: 500px;
  height: 200px;
  background-color: #cbd0df;
  border-radius: 2px;
  position: relative;
  text-align: left;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  float: left;
}

.timeline-panel:after {
  content: "";
  position: absolute;
  margin-top: -12%;
  left: 10%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom: 15px solid #cbd0df;
}

.bordure::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 56px;
  top: -30px;
  z-index: -1;
  transform: rotate(10deg);
  background: url("https://static.mediarestauration.fr/img/carte-3.jpg");
}

.bordure {
  position: relative;
  margin: 0;
  display: inline-block;
  border: 1px solid transparent;
  transform: rotate(-2deg);
}

.bordure::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -59px;
  top: -30px;
  z-index: -1;
  transform: rotate(-4deg);
  background: url("https://static.mediarestauration.fr/img/carte-2.jpg");
}
